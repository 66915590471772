"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Logo_company } from "@/public";
import { usePathname } from "next/navigation";

export default function nav_1() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [onScroll, setOnScroll] = useState(false);
  const [inHero, setInHero] = useState(false);
  const pathname = usePathname();

  const changeColor = () => {
    if (window.scrollY >= 35) setOnScroll(true);
    else setOnScroll(false);
  };

  const isInHero = () => {
    const hero = document.getElementById("hero-section");
    if (hero) {
      const heroHeight = hero.clientHeight;
      if (window.scrollY < heroHeight) setInHero(true);
      else setInHero(false);
    } else setInHero(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    window.addEventListener("scroll", isInHero);
  }, []);

  useEffect(() => {
    isInHero();
    console.log(pathname);
  }, [pathname]);

  return (
    <header className="bg-transparent w-full fixed top-0 left-0 z-20">
      <nav
        className={`${
          onScroll
            ? "bg-[#181623] transition-all duration-500 pt-0 md:pt-2 px-10 md:px-24 lg:px-48 border-black shadow-lg"
            : "shadow-xl bg-white transition-all duration-500 pt-0 md:py-4 px-8 md:px-20 lg:px-40"
        } relative flex justify-between items-center py-2`}
      >
        <Link
          className="text-xl font-bold leading-none flex flex-row justify-center items-center text-[#131315]"
          href="/"
          aria-label="Homepage"
        >
          <Image
            alt="logo Flash Report"
            className="h-12 w-12 opacity-100"
            src={Logo_company}
          />
          <span
            className={`hidden md:block opacity-100 ${
              onScroll ? "text-white" : "text-black"
            }`}
          >
            Flash Report
          </span>
        </Link>
        <button
          className={`lg:hidden navbar-burger flex items-center text-black p-3 ${
            !menuOpen ? "" : "hidden"
          }`}
          aria-label="Toggle menu"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg
            className="block h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            fill={`${onScroll ? "white" : "black"} `}
          >
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
        <div className="flex items-center justify-center gap-4">
          <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-6 lg:ml-16 lg:gap-8">
            <li>
              <Link
                className={`text-sm font-bold ${
                  onScroll
                    ? "text-white hover:text-gray-600"
                    : "text-[#131315] hover:text-gray-400"
                }`}
                href="/pricing"
                aria-label="pricing"
              >
                Pricing
              </Link>
            </li>
            <li>
              {/* <Link
              className={`text-sm font-bold ${
                onScroll
                  ? "text-white hover:text-gray-600"
                  : "text-[#131315] hover:text-gray-400"
              }`}
              href="#"
              aria-label="agenda"
            >
              Planifiez une Démo
            </Link> */}
            </li>
          </ul>
          <Link
            className="hidden lg:inline-block lg:mr-3 py-2 px-6 bg-white border-2 border-[#131315] text-sm text-[#131315] hover:bg-black hover:text-white font-bold rounded-lg transition duration-200"
            href="/contact"
          >
            Nous contacter
          </Link>
        </div>
      </nav>

      {/* Encart promotionnel, affiché sauf dans la section Hero */}
      {/*
      {!inHero && (
        <div
          className={`fixed w-full left-0 transition-transform duration-500 z-10 flex justify-center items-center top-14 h-12 bg-gradient-to-r from-green-500 via-purple-500 to-purple-700 ${
            onScroll ? "mt-2" : "mt-4"
          }`}
        >
          <p className="text-sm sm:text-base font-semibold text-white text-center drop-shadow-md">
            🎉 NOUVEAU! Flash Report en accès gratuit jusqu'au 9 décembre!{" "}
            <Link
              href="/demo"
              className="underline text-white font-bold hover:text-green-200"
            >
              Cliquez ici pour en profiter
            </Link>
          </p>
        </div>
      )}
      */}

      {menuOpen && (
        <div className="navbar-menu relative z-50">
          <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <Link
                className="mr-auto text-3xl font-bold leading-none  flex flex-row justify-center items-center"
                href="/"
                aria-label="Homepage"
              >
                <Image
                  alt="logo Flash Report"
                  className="h-16 w-16"
                  src={Logo_company}
                />{" "}
                Flash Report
              </Link>
              <button
                className="navbar-close"
                aria-label="Close menu"
                onClick={() => setMenuOpen(false)}
              >
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                    href="/"
                  >
                    Accueil
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                    href="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
                {/* <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                    href="#"
                  >
                    Planifiez une Démo
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="mt-auto">
              <div className="pt-6">
                <Link
                  className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-black text-white hover:text-[#4F46E5] rounded-lg"
                  href="/contact"
                >
                  Nous contacter
                </Link>
              </div>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
}
