"use client";
import { useRouter } from "next/navigation";
import React, { useState, useEffect } from "react";
import { forms } from "@/constants/arrayFormDemo";

type props = {};

const DemoForm = () => {
  const router = useRouter();

  const [formData, setFormData] = useState(
    forms.reduce((acc, form) => {
      acc[form.key] = "";
      return acc;
    }, {} as Record<string, string>)
  );

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Obtenez votre accès gratuit !");

  useEffect(() => {
    const allRequiredFieldsFilled = forms
      .filter((form) => form.obligatory)
      .every((form) => formData[form.key]?.trim() !== "");

    const requiredFieldsErrors = Object.keys(errors).some(
      (key) => forms.find((form) => form.key === key)?.obligatory && errors[key]
    );

    setIsSubmitDisabled(!allRequiredFieldsFilled || requiredFieldsErrors);
  }, [formData, errors]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    const value = e.target.value;
    const field = forms.find((form) => form.key === key);

    if (field?.regex && !field.regex.test(value)) {
      setErrors((prev) => ({
        ...prev,
        [key]: field.errorMessage || "Invalid input.",
      }));
    } else {
      setErrors((prev) => {
        const updatedErrors = { ...prev };
        delete updatedErrors[key];
        return updatedErrors;
      });
    }

    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setButtonText("Envoi en cours...");

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: formData.email,
          dynamicData: {},
        }),
      });
      if (response.ok) {
        const dbResponse = await fetch("/api/sendDataDB", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formData: formData,
          }),
        });
        if (dbResponse.ok) {
          router.push("/?emailSent=true");
          return;
        }
      }
      throw new Error("API request failed");
    } catch (error) {
      setButtonText("Erreur, veuillez réessayer");
      setTimeout(() => {
        setButtonText("Réservez votre Démo");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="w-full lg:w-1/2 h-full pt-4 lg:pt-36 flex flex-col">
      <div className="shadow-md mt-12 w-full h-full border rounded-tl-lg">
        <form
          className="w-full flex flex-col justify-center items-start px-8 lg:px-16 pt-8 pb-16"
          aria-label="Signup Form"
          onSubmit={handleSubmit}
        >
          {forms.map((item, index) => (
            <div
              className="mt-4 lg:mt-2 flex flex-col lg:flex-row gap-0 lg:gap-8 w-full"
              key={index}
            >
              <div className="w-full lg:w-1/3">
                <label className="block mb-2 text-sm text-black dark:text-gray-200 h-full flex items-center justify-start">
                  {item.label} {item.obligatory ? "*" : ""}
                </label>
              </div>
              <div className="w-full lg:w-2/3">
                {item.type === "textarea" ? (
                  <textarea
                    placeholder={item.default_content}
                    className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-[#F5F9FC] rounded-md ${
                      errors[item.key]
                        ? "border-red-500"
                        : "focus:ring-violet-400 focus:outline-none"
                    }`}
                    aria-label={item.label}
                    value={formData[item.key] || ""}
                    onChange={(e) => handleChange(e, item.key)}
                  />
                ) : (
                  <input
                    type={item.type}
                    placeholder={item.default_content}
                    className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-[#F5F9FC] rounded-md ${
                      errors[item.key]
                        ? "border-red-500"
                        : "focus:ring-violet-400 focus:outline-none"
                    }`}
                    aria-label={item.label}
                    value={formData[item.key] || ""}
                    onChange={(e) => handleChange(e, item.key)}
                  />
                )}
                {errors[item.key] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[item.key]}
                  </p>
                )}
              </div>
            </div>
          ))}

          <button
            type="submit"
            className={`mt-4 md:mt-8 flex items-center justify-between px-5 py-3 text-base text-white rounded-lg transition duration-200 ${
              isSubmitDisabled || isLoading
                ? "bg-gray-400"
                : "bg-[#131315] hover:bg-[#140f6b]"
            }`}
            aria-label="Send message"
            disabled={isSubmitDisabled || isLoading}
          >
            {buttonText}
          </button>
        </form>
      </div>
    </section>
  );
};

export default DemoForm;
