const forms = [
  {
    key: "fullName",
    label: "Nom et Prénom",
    default_content: "Ex: Jane Doe",
    obligatory: true,
    type: "text",
    regex: /^[a-zA-ZÀ-ÿ' -]+$/,
    errorMessage:
      "Utilisez uniquement des lettres, espaces, apostrophes, et tirets.",
  },
  {
    key: "email",
    label: "Adresse e-mail professionnelle",
    default_content: "Ex: jane.doe@entreprise.com",
    obligatory: true,
    type: "email",
    regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    errorMessage: "Veuillez entrer une adresse e-mail valide.",
  },
  {
    key: "phone",
    label: "Numéro de téléphone",
    default_content: "Ex: +33 6 12 34 56 78",
    obligatory: false,
    type: "tel",
    regex: /^\+?[0-9\s]{7,15}$/,
    errorMessage: "Entrez un numéro de téléphone valide.",
  },
  {
    key: "companyName",
    label: "Nom de l'entreprise",
    default_content: "Ex: Entreprise Innovante",
    obligatory: false,
    type: "text",
    regex: /^[\w\s&.,'-]{2,}$/,
    errorMessage:
      "Utilisez uniquement des lettres, chiffres et caractères acceptés.",
  },
  {
    key: "industry",
    label: "Secteur d'activité de l'entreprise",
    default_content: "Ex: Technologie de l'information",
    obligatory: false,
    type: "text",
    regex: /^[\w\s&.,'-]{2,}$/,
    errorMessage:
      "Utilisez uniquement des lettres, chiffres et caractères acceptés.",
  },
  {
    key: "employeeCount",
    label: "Nombre d'employés",
    default_content: "Ex: 50",
    obligatory: false,
    type: "number",
    regex: /^[1-9][0-9]*$/,
    errorMessage: "Entrez un nombre valide (1 ou plus).",
  },
  {
    key: "jobTitle",
    label: "Fonction / Métier",
    default_content: "Ex: Responsable Marketing",
    obligatory: true,
    type: "text",
    regex: /^[a-zA-ZÀ-ÿ' -]+$/,
    errorMessage:
      "Utilisez uniquement des lettres, espaces, apostrophes, et tirets.",
  },
  {
    key: "applicationGoal",
    label: "Objectif avec l'application",
    default_content: "Ex: Améliorer l'efficacité de l'équipe",
    obligatory: false,
    type: "textarea",
    regex: /^.{0,500}$/,
    errorMessage: "Maximum 500 caractères.",
  },
  {
    key: "reasonForChoosingUs",
    label: "Pourquoi choisir notre solution ?",
    default_content: "Ex: Fonctionnalités adaptées à nos besoins",
    obligatory: false,
    type: "textarea",
    regex: /^.{0,500}$/,
    errorMessage: "Maximum 500 caractères.",
  },
  {
    key: "referralSource",
    label: "Comment avez-vous entendu parler de nous ?",
    default_content: "Ex: Recommandation, réseaux sociaux, etc.",
    obligatory: false,
    type: "textarea",
    regex: /^.{0,500}$/,
    errorMessage: "Maximum 500 caractères.",
  },
];

export { forms };
