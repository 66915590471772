import Footer_2 from "./common/footer/footer_2";

import JSONLD from "./common/component/JSONLD";
import Nav_1 from "./common/nav/nav_1";
import Simulator_pricing from "./common/component/simulator_pricing";
import Simulator_hero from "./common/component/simulator_hero";
import Gauge_simulator from "./common/component/gauge_simulator";
import Title_feature_IA from "./common/component/title_feature_IA";
import DemoForm from "./common/component/demoForm";
import ModalEmailSent from "./common/component/modal-emailSent";

export {
  Footer_2,
  Nav_1,
  JSONLD,
  Simulator_pricing,
  Simulator_hero,
  Gauge_simulator,
  Title_feature_IA,
  DemoForm,
  ModalEmailSent,
};
